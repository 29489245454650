import React from "react";
import "./App.css";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import logo from "./DM7Logo.svg";
import swal from "sweetalert";
import copy from 'copy-to-clipboard';

function App() {
  const [dtFinal, setFinal] = React.useState<Number>(() => {
    let date = new Date();
    date = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);
    let converted =
      25569.0 +
      (date.getTime() - date.getTimezoneOffset() * 60 * 1000) /
        (1000 * 60 * 60 * 24);
    return converted;
  });

  const [dtInicio, setInicio] = React.useState<Number>(() => {
    let date = new Date();
    date = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    let converted =
      25569.0 +
      (date.getTime() - date.getTimezoneOffset() * 60 * 1000) /
        (1000 * 60 * 60 * 24);
    return converted;
  });

  const [valueinicial, setValueInicial] = React.useState<Dayjs | null>(
    dayjs(new Date())
  );

  const [valuefinal, setValueFinal] = React.useState<Dayjs | null>(() => {
    var date = new Date();
    date.setDate(date.getDate() + 1);
    return dayjs(date);
  });

  const [GetMaxExec, setGetMaxExec] = React.useState("3000");
  const [nrSerie, setNrSerie] = React.useState("");
  const [idApplication, setIDApplication] = React.useState(1100314385);
  const [codliberacao, setCodLiberacao] = React.useState("");

  const handleGetMaxExec = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGetMaxExec(event.target.value.substring(1, 5).padStart(4, "0"));
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const serie = event.target.value.substring(0, 14);
    const haspid = event.target.value.substring(14, event.target.value.length);
    const idaplicacao =
      (8345623 + parseInt(haspid.substring(3, 7))) *
      parseInt(haspid.substring(3, 4));
    if (event.target.value.length > 14) {
      setNrSerie(serie);
      setIDApplication(idaplicacao);
    } else {
      setNrSerie(event.target.value);
    }
  };

  const handleidApplication = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIDApplication(parseInt(event.target.value));
  };

  const Uncriptografe = (S: string, K: string) => {
    let result = "";
    for (let i = 1; i < S.length + 1; i++) {
      const JK = parseInt(K[i % K.length], 16);
      const JS = parseInt(S[i - 1], 16);
      result += (JK ^ JS).toString(16);
    }
    return result;
  };

  const GetKey = (S: string) => {
    let result = "";
    let T = 0;

    for (let i = 0; i < S.length; i++) {
      T += parseInt(S[i], 16) * (i + 1);
    }
    result = (T % 256).toString(16).padStart(2, "0");
    return result;
  };

  const dataConvertida = (data: Dayjs | null) => {
    let date = new Date();
    date = new Date(
      Number(data?.toDate().getFullYear()),
      Number(data?.toDate().getMonth()),
      Number(data?.toDate().getDate())
    );
    let converted =
      25569.0 +
      (date.getTime() - date.getTimezoneOffset() * 60 * 1000) /
        (1000 * 60 * 60 * 24);
    return converted;
  };

  const buttonCode = async () => {
    const serie = nrSerie.substring(0, 12);
    const Body = Uncriptografe(serie, idApplication.toString(16));
    const GetInitialDate = dtInicio.toString(16).padStart(4, "0");
    const GetFinalDate = dtFinal.toString(16).padStart(4, "0");
    const GetMaxExecucao = parseInt(GetMaxExec).toString(16).padStart(4, "0");
    if (nrSerie.length !== 14) {
      swal({
        title: "A entrada do número de série está incompleta\n",
        icon: "success",
        dangerMode: true,
      });
      return null;
    }

    if (GetKey(Body).toUpperCase() !== nrSerie.substring(12, 14)) {
      swal({
        title: "A entrada do número de série não está correta [1]\n",
        icon: "success",
        dangerMode: true,
      });
      return null;
    }

    const CodLib =
      GetInitialDate + GetFinalDate + GetMaxExecucao + Body.substring(0, 4);
    const codLiberacao =
      Uncriptografe(CodLib, Body.substring(4, 12)) + GetKey(CodLib);
    setCodLiberacao(codLiberacao.toUpperCase());
    copy(codLiberacao.toUpperCase());
  };

  return (
    <Box
      className="Box-header"
      padding={1}
      component="form"
      sx={{
        "& > :not(style)": { m: 1 },
      }}
      noValidate
      autoComplete="off"
    >
      <div className="App-header">
        <img className="App-logo" src={logo} alt="Logo" />
      </div>
      <TextField
        id="idApplication"
        label="ID Application"
        value={idApplication}
        type="password"
        onChange={handleidApplication}
      />
      <TextField
        id="nrSerie"
        label="Número de Série"
        value={nrSerie}
        onClick={() => setNrSerie("")}
        onChange={handleChange}
      />
      <TextField
        id="GetMaxExec"
        label="Número máximo de execuções"
        value={GetMaxExec}
        onClick={() => setGetMaxExec("0000")}
        onChange={handleGetMaxExec}
      />

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MobileDatePicker
          label="Inicio da Liberação"
          value={valueinicial}
          onChange={(newValue) => {
            setValueInicial(newValue);
          }}
          onAccept={(newValue) => {
            setInicio(dataConvertida(newValue));
          }}
          renderInput={(params) => <TextField {...params} />}
        />
        <MobileDatePicker
          label="Final da Liberação"
          value={valuefinal}
          onChange={(newValue) => {
            setValueFinal(newValue);
          }}
          onAccept={(newValue) => {
            setFinal(dataConvertida(newValue));
          }}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
      <Stack spacing={2} direction="row" className="App-button">
        <Button onClick={buttonCode} variant="contained">
          Gerar Liberação
        </Button>
      </Stack>
      <TextField
        id="codliberacao"
        label="Código de Liberação"
        value={codliberacao}
      />
      <p style={{ textAlign: "center", width: "95%" }}>
        © 2023 DM7 Sistemas. Todos os direitos reservados. Versão: Jan/2023A.
      </p>
    </Box>
  );
}

export default App;
